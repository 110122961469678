import Group from "@mixitone/models/Group";
import { ApplicationView } from "@mixitone/mvc";
import { twMerge } from "tailwind-merge";
import { GroupDot } from "./GroupDot";
import { AllDot } from "./AllDot";
import { ColorDot } from "./ColorDot";

interface Props {
  group: Group;
  className?: string;
}

export const GroupDotName = ApplicationView(({ group, className }: Props) => {
  return (
    <div className={twMerge("flex items-center justify-start gap-2", className)}>
      <GroupDot group={group} size={16} />
      <span className="group-name">{group.name}</span>
    </div>
  );
});

export const AllGroup = () => {
  return (
    <div className="flex items-center justify-start gap-2">
      <AllDot />
      <span>All</span>
    </div>
  );
};

export const NoneGroup = () => {
  return (
    <div className="flex items-center justify-start gap-2">
      <ColorDot color="#666" size={16} />
      <span>None</span>
    </div>
  );
};
