/**
 * TypeScript type utilities
 */

export type Nullable<T> = T | null | undefined;
export type NonNullable<T> = T extends null | undefined ? never : T;
export type PropsWithClassName<P = unknown> = P & { className?: string | undefined };

const isNilSymbol = Symbol("isNil");
export { isNilSymbol };

export function isNil(value: any): value is null | undefined {
  if (value && value[isNilSymbol]) return true;
  return value === null || value === undefined;
}

export function isPresent<T>(value: T | null | undefined): value is T {
  return !isNil(value);
}

export function isUndefined(value: any): value is undefined {
  return value === undefined;
}
