import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

export interface ConvertOptions {
  includeHours?: boolean;
}

export function convertMillisecondsToReadableDuration(
  milliseconds: number,
  options?: ConvertOptions,
): string {
  const hours = Math.floor(milliseconds / (1000 * 60 * 60));
  const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);

  const paddedHours = hours.toString().padStart(2, "0");
  const paddedMinutes = minutes.toString().padStart(2, "0");
  const paddedSeconds = seconds.toString().padStart(2, "0");

  const parts: string[] = [];
  if (options?.includeHours || hours > 0) parts.push(paddedHours);
  parts.push(paddedMinutes);
  parts.push(paddedSeconds);
  return parts.join(":");
}

export function timesToDuration(startedAt: Date, stoppedAt?: Date): number {
  if (startedAt) {
    if (stoppedAt) {
      return Math.abs(dayjs(stoppedAt).diff(dayjs(startedAt)));
    } else {
      return Math.abs(dayjs().diff(dayjs(startedAt)));
    }
  } else {
    return 0;
  }
}

const formatter = new Intl.DateTimeFormat(undefined, {
  timeStyle: "short",
});

export function formatTime(time: string | Date) {
  if (time instanceof Date) return formatter.format(time);
  const date = new Date("1/1/1 " + time);
  return formatter.format(date);
}

export function formatDate(date: Date | string | null | undefined) {
  return date ? dayjs(date).format("ll") : "never";
}

export function compareDateStringOrNull(a: string | null | undefined, b: string | null | undefined): number {
  if (a == null && b == null) return 0;
  if (a == null) return 1;
  if (b == null) return -1;

  return dayjs(b).startOf("day").diff(dayjs(a).startOf("day"));
}

export function formatRelativeTime(pastDate: dayjs.ConfigType) {
  const now = dayjs();
  const past = dayjs(pastDate);
  const diffDays = now.diff(past, "day");

  if (now.isSame(past, "day")) {
    return "today";
  } else if (diffDays <= 7) {
    return `last ${past.format("dddd")}`; // Returns the full name of the day of the week
  } else if (diffDays <= 365) {
    return past.fromNow(); // This will return something like "6 months ago"
  } else {
    return "more than a year ago";
  }
}
