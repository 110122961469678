import { ApplicationView } from "@mixitone/mvc";
import clsx from "clsx";
import useGlobalShortcut from "../hooks/useGlobalShortcut";
import CloseIcon from "../icons/close.svg";
import { FloatingPortal } from "@floating-ui/react";
import { useCallback, useRef } from "react";
import { twMerge } from "tailwind-merge";
import { prefersReducedMotion } from "../animation";

interface Props extends React.PropsWithChildren {
  onClose: () => void;
  title?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  portal?: boolean;
}

const ControlledModal: React.FC<Props> = ({ onClose, title, children, className, style, portal }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const handleClose = useCallback(async () => {
    if (modalRef.current && !prefersReducedMotion.matches) {
      // await animateFadeTo(modalRef.current, 1.0, 0, { duration: 100 });
    }

    if (onClose) {
      return onClose();
    }
  }, [onClose]);

  useGlobalShortcut("Escape", handleClose);

  const handleBackgroundClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  const modal = (
    <div
      ref={modalRef}
      className={clsx(
        "max-w-screen fixed inset-0 z-50 flex max-h-screen items-center justify-center bg-white bg-opacity-80 transition-opacity",
      )}
      onClick={handleBackgroundClick}
    >
      <div
        className={twMerge(
          "flex h-full max-h-full w-full flex-col border border-gray-300 bg-white px-2 pb-6 shadow-md sm:mx-6 sm:h-auto sm:w-auto sm:min-w-[400px] sm:rounded-lg sm:px-6",
          className,
        )}
        role="dialog"
        style={style}
        data-testid={`modal-${title}`}
      >
        {title && (
          <div className="-mx-2 mb-4 flex items-center justify-between bg-slate-800 px-4 py-2 text-white sm:-mx-6 sm:rounded-t-lg">
            <h2 className="text-xl font-normal">{title}</h2>
            <button onClick={handleClose} title="Close" className="cursor-pointer">
              <CloseIcon className="w-[30px] fill-white transition-transform hover:scale-125 sm:w-[19px]" />
            </button>
          </div>
        )}
        {children}
      </div>
    </div>
  );

  if (portal) {
    return <FloatingPortal>{modal}</FloatingPortal>;
  }

  return modal;
};

const Modal = ApplicationView(ControlledModal);
export { Modal };
