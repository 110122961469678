import ApplicationController, { ApplicationView } from "@mixitone/mvc";
import clsx from "clsx";
import { PropsWithChildren, useEffect, useId, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { twMerge } from "tailwind-merge";
import useOutsideAlerter from "../hooks/useOutsideAlerter";
import { Spinner } from "./Spinner";
import DrawerCloseIcon from "../icons/drawer-close.svg";

interface DrawerProps {
  open: boolean;
  spinner?: boolean;
  children: React.ReactNode;
  onClose: () => void;
  className?: string;
  viewTransitionName?: string;
}

interface State {
  open: boolean;
  spinner: boolean;
}

export class DrawerController extends ApplicationController<State, DrawerProps> {
  static override initialState: Partial<State> = {
    open: false,
  };

  onClose?: () => void;

  override async changeProps(newProps: DrawerProps) {
    this.setState({ open: newProps.open, spinner: newProps.spinner });
    this.onClose = newProps.onClose;
  }

  actionClose() {
    if (!this.state.open) return;

    if (this.onClose) {
      this.onClose();
    } else {
      this.setState({ open: false });
    }
  }
}

const ControlledDrawer: React.FC<DrawerProps> = ({ children, viewTransitionName }) => {
  const controller = DrawerController.use();
  const { open, spinner } = controller.state;
  const [deferredOpen, setDeferredOpen] = useState(false);

  const drawerRef = useRef<HTMLDivElement>(null);

  useOutsideAlerter(
    drawerRef,
    () => {
      controller.actionClose();
    },
    { skipElements: ["[role=menu]", "[role=option]"], active: open },
    [open],
  );

  const handleBackgroundClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    controller.actionClose();
  };

  const id = useId();
  const [drawerContainer, _setDrawerContainer] = useState(() => {
    const container = document.createElement("div");
    container.id = id;
    return container;
  });

  useEffect(() => {
    document.body.appendChild(drawerContainer);

    return () => {
      document.body.removeChild(drawerContainer);
    };
  }, [drawerContainer]);

  useEffect(() => {
    setDeferredOpen(open);
  }, [open]);

  return createPortal(
    <div
      className="fixed left-0 top-0 z-40 h-screen w-screen bg-black bg-opacity-10"
      onClick={handleBackgroundClick}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        style={{ viewTransitionName: viewTransitionName }}
        className={clsx(
          [
            "fixed",
            "right-0",
            "top-0",
            "z-40",
            "h-screen",
            "w-full sm:w-[500px]",
            "pt-10",
            "transition-transform",
          ],
          {
            "translate-x-full": !deferredOpen,
          },
        )}
      >
        <div
          className={clsx([
            "w-full",
            "h-full",
            "overflow-y-auto",
            "border-l",
            "border-t",
            "rounded-tl-lg",
            "border-slate-300",
            "bg-slate-100",
            "shadow-xl shadow-gray-400",
            "p-4",
          ])}
          tabIndex={-1}
          aria-labelledby="drawer-right-label"
          aria-roledescription="drawer"
          aria-hidden={!deferredOpen}
          ref={drawerRef}
        >
          {spinner && <DrawerSpinner />}
          {!spinner && children}
        </div>
      </div>
    </div>,
    drawerContainer,
  );
};

const DrawerTitleComponent: React.FC<React.PropsWithChildren<{ icon?: React.ReactNode }>> = ({
  icon,
  children,
}) => {
  const controller = DrawerController.use();

  return (
    <div
      className="mb-4 flex items-center justify-between text-base font-semibold text-gray-800"
      role="heading"
    >
      <div className="flex items-center">
        {icon || (
          <svg
            className="mr-2.5 h-4 w-4"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
          </svg>
        )}
        {children}
      </div>
      <button
        onClick={() => controller.actionClose()}
        className="rounded-full p-1 hover:bg-gray-200"
        aria-label="Close drawer"
      >
        <DrawerCloseIcon className="h-[20px] w-[20px] text-gray-500" />
      </button>
    </div>
  );
};
const DrawerTitle = ApplicationView(DrawerTitleComponent);
export { DrawerTitle };

const DrawerSpinnerComponent: React.FC = () => {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <Spinner size={64} />
    </div>
  );
};
const DrawerSpinner = ApplicationView(DrawerSpinnerComponent);
export { DrawerSpinner };

const Drawer = DrawerController.scope(ApplicationView(ControlledDrawer));
export { Drawer };

export const DrawerCard: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  return <div className="flex w-full flex-col gap-5 rounded bg-white p-4">{children}</div>;
};

export const DrawerField = ApplicationView(
  ({
    label,
    className,
    children,
  }: {
    label: React.ReactNode;
    className?: string;
    children: React.ReactNode;
  }) => {
    return (
      <div className={twMerge("drawer-field flex items-center", className)}>
        <label className="w-1/3 text-gray-600">{label}</label>
        <div className="flex w-full items-center gap-2 pl-2">{children}</div>
      </div>
    );
  },
);
