/**
 * Object manipulation utilities
 */

export function pick<T extends object, K extends keyof T>(obj: T, keys: K[]): Pick<T, K> {
  const result: Partial<T> = {};
  for (const key of keys) {
    result[key] = obj[key];
  }
  return result as Pick<T, K>;
}

export function omit<T extends object, K extends keyof T>(obj: T, keys: K[]): Omit<T, K> {
  const result: Partial<T> = { ...obj };
  for (const key of keys) {
    delete result[key];
  }
  return result as Omit<T, K>;
}

export function keys<T extends object>(object: T): Array<keyof T> {
  return Object.keys(object) as Array<keyof T>;
}

export function entries<T extends object>(object: T): [keyof T, T[keyof T]][] {
  return Object.entries(object) as [keyof T, T[keyof T]][];
}

export function isObjectLike(value: any): boolean {
  return typeof value === "object" && value !== null;
}

export function mapValues<T extends object, R>(
  obj: T,
  callback: (value: T[keyof T], key: keyof T, obj: T) => R,
): { [K in keyof T]: R } {
  const result: Partial<{ [K in keyof T]: R }> = {};
  for (const key in obj) {
    result[key] = callback(obj[key], key, obj);
  }
  return result as { [K in keyof T]: R };
}

export function mapKeys<T extends object, K extends string>(
  obj: T,
  callback: (key: keyof T, value: T[keyof T], obj: T) => K,
): { [P in K]: T[keyof T] } {
  const result: Partial<{ [P in K]: T[keyof T] }> = {};

  for (const key in obj) {
    const newKey = callback(key, obj[key], obj);
    result[newKey] = obj[key];
  }

  return result as { [P in K]: T[keyof T] };
}

type KeyOrKeyFn<T, R = any> = keyof T | ((item: T) => R);

export function keyBy<T extends object>(array: T[], keyOrKeyFn: KeyOrKeyFn<T>): Record<string, T> {
  const keyFn = typeof keyOrKeyFn === "function" ? keyOrKeyFn : (item: T) => item[keyOrKeyFn];

  const result: Record<string, T> = {} as any;
  for (const item of array) {
    result[String(keyFn(item))] = item;
  }
  return result;
}

export function indexBy<T extends object>(array: T[], keyOrKeyFn: KeyOrKeyFn<T>): Record<string, T[]> {
  const keyFn = typeof keyOrKeyFn === "function" ? keyOrKeyFn : (item: T) => item[keyOrKeyFn];

  return array.reduce(
    (result, item) => {
      const key = String(keyFn(item));
      if (!result[key]) result[key] = [];
      result[key].push(item);
      return result;
    },
    {} as Record<string, T[]>,
  );
}

export function makeRecordSetRelationshipsBidirectional(relationships: Record<string, Set<string>>) {
  const keys = Object.keys(relationships);

  for (const source of keys) {
    const relatedItems = relationships[source];

    for (const target of relatedItems) {
      // Create a Set for the target if it doesn't exist
      if (!relationships[target]) {
        relationships[target] = new Set();
      }

      // Add the source to the target's set
      relationships[target].add(source);
    }
  }
}
