import { ErrorBoundary } from "@sentry/react";
import ApplicationController, { ApplicationView } from "@mixitone/mvc";
import clsx from "clsx";

type NodeOrFunction = React.ReactNode | (() => React.ReactNode);

type PanelProps = {
  title: React.ReactNode;
  buttons?: NodeOrFunction;
  children: React.ReactNode;
  className?: string;
  titleClassName?: string;
};

const ErrorState = () => {
  return (
    <div className="flex h-full items-center justify-center">
      <div className="-translate-y-5 text-center">
        <p className="mb-4 text-lg font-medium">Something went wrong</p>
        <p className="text-sm text-gray-600">Please try again later</p>
      </div>
    </div>
  );
};

export class PanelController extends ApplicationController<{ buttons: NodeOrFunction }, PanelProps> {
  static initialState = {
    buttons: null,
  };

  buttonsSet: boolean = false;
  buttonsFromProps?: NodeOrFunction;

  override async changeProps(newProps: PanelProps) {
    this.buttonsFromProps = newProps.buttons;

    if (!this.buttonsSet) {
      this.state.buttons = newProps.buttons;
    }
  }

  actionSetButtons(buttons: React.ReactNode) {
    if (buttons) {
      this.buttonsSet = true;
      this.state.buttons = buttons;
    } else {
      this.buttonsSet = false;
      this.state.buttons = this.buttonsFromProps;
    }
  }
}

const PanelComponent = ApplicationView(({ title, titleClassName, className, children }: PanelProps) => {
  const controller = PanelController.use();
  const { buttons } = controller.state;

  return (
    <div className={clsx("panel group/panel rounded-lg bg-white p-6 shadow-sm", className)}>
      <div className="flex h-full flex-col">
        <div className="mb-4 flex items-center justify-between">
          <h2 className={clsx("text-lg font-medium", titleClassName)}>{title}</h2>
          <div>{typeof buttons === "function" ? buttons() : buttons}</div>
        </div>
        <ErrorBoundary fallback={<ErrorState />}>{children}</ErrorBoundary>
      </div>
    </div>
  );
});

export const Panel = PanelController.scope(PanelComponent);
